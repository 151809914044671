$c1:green;
$c1-dark:darkgreen;

$c2:#929292;
// $c1:red;
// $c1-dark:violet;
$c3-light:#eb05c4;
$c3:#a21089;

.page_container{
  position: relative;
  width: 100%;
  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
}