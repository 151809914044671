.login_page_container{
    position: relative;
    overflow: hidden;
    width:100%;
    height:100vh;
    // background-color: #71d2b8;
    background-image: linear-gradient(to bottom right, #5bbdbd, #1fc899);
    .login_container{
        position: absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        overflow: hidden;
        width: 900px;
        max-width: 90%;
        height: auto;
        .grid_container{
            display:grid;
            grid-template-columns: 50% auto;
            height:600px;
            background-color: white;
            border-radius: 20px;
            .image_container{
                background-color: green;
                border-radius: 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .text_block{
                background-color: white;
                justify-self: center;
                align-self: center;
                width:70%;
                // margin-left: auto;
                // margin-right: auto;
                // .text_container{
                //     position:relative;
                //     overflow: hidden;
                //     width:auto;
                //     height:auto;
                //     color:white;
                //     background-color: aqua;
                    .signin{
                        position: relative;
                        overflow:hidden;
                        font-size: 40px;
                        
                    }
                    .input_label{
                        position: relative;
                        overflow:hidden;
                        padding-top: 20px;
                        padding-bottom: 5px;
                        width:100%;
                        font-size: 20px;

                    }
                    .input_fields{
                        position: relative;
                        overflow:hidden;
                        box-sizing: border-box;
                        padding: 20px 0px;
                        padding-left: 10px;
                        width:100%;
                        height: 35px;
                        border-radius: 5px;
                        border: 1px solid rgb(234,224,224);    
                    }
                    .signin_button{
                        position: relative;
                        overflow:hidden;
                        margin-top: 20px;
                        padding: 15px 0px;
                        box-sizing: border-box;
                        width: 100%;
                        // height:25px;
                        // background-color: #18a47e;
                        background-image: linear-gradient(to bottom right, #149c77,#1fc899);

                        text-align: center;
                        border-radius: 8px;
                        color:white;
                        cursor: pointer;
                    }
                // }
                    
                
                
            }
        }
    }
    @media screen and (max-width:700px){
     .login_container{
        .grid_container{
            grid-template-columns: 100%;
            .image_container{
                display:none;
            }
        }
     }   
    }
    
}