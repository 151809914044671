@import "../theme.scss";

@mixin auto_arrange($child_max_width : 300px) {
  display: grid;
  justify-content: space-evenly;
  column-gap: 10px;
  row-gap: 30px;
  grid-template-columns: repeat(auto-fill,max($child_max_width));
  // #{$child_max_width}: $max-size;
  > *{
    margin: 0px !important;
  }
  @media (max-width:($child_max_width*2)){
    display: revert;
    text-align: center;
    *{
      text-align: initial;
    }
    > *{
      margin-left: auto;
      margin-right: auto;
      // max-width: $child_max_width;
      width: 100%;
    }
    > *:not(:last-child){
      margin-bottom: 30px !important;
    }
  }
}

.button_theme1{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;

  color: white;
  font-size: 15px;

  border-radius: 3px;
  background-color: $c1;
  border: 2px solid $c1;
  cursor: pointer;
  transition: 0.3s all;
  &:hover{
    background-color: white;
    color: $c1-dark;
  }
}

.button_theme2{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 50%;
  // padding: 0px 20px;

  color: white;
  font-size: 13px;

  border-radius: 3px;
  background-color: $c1;
  border: 2px solid $c1;
  cursor: pointer;
  transition: 0.3s all;
  &:hover{
    background-color: white;
    color: $c1-dark;
  }
}

.block_theme1{
  position: relative;
  width: 400px;
  width: 100%;
  height: auto;
  // max-width: 90%;
  margin: 20px auto;
  padding: 20px;
  overflow: hidden;
  box-sizing:border-box;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 9px darkslategrey;
}
.block_theme2{
  position: relative;
  // width: 400px;
  width: 100%;
  height: auto;
  // max-width: 90%;
  margin: 20px auto;
  padding: 20px;
  overflow: hidden;
  box-sizing:border-box;
  border-radius: 10px;
  background-color: #ff85858c;

  transition: all 0.2s;
  cursor: pointer;
  &:hover{
    background-color: #ff52528c;

  }
}

.title_theme1{
  position: relative;
  width: 100%;
  padding: 10px 5px;
  font-weight: 500;
  font-size: 18px;
  display: grid;
  grid-template-columns:max-content auto;
  grid-column-gap:10px;
  color: white;
}
.title_theme2{
  padding-bottom: 5px;
  border-bottom: 2px solid $c3;
  margin-bottom: 10px;
}

.lctc{
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.no_select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

input[type="number"]{
  -moz-appearance: textfield;
}

button{
  outline: none;
}

a{
  color: inherit;
  text-decoration: none;
}