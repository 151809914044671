.imageupload_page_container{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: #9aa6a9;  
    
    .test{
        position:relative;
        width:100px;
        height: 50px;
        margin-top: 100px;
        background-color:blue;
    }
    .upload_block{
        position: relative;
        overflow: hidden;
        margin-top: 200px;
        // box-sizing: border-box;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        padding: 30px;
        box-sizing: border-box;
        border-radius: 15px;
        background-color: white;
        text-align: center;
        .image_input{
            width:100%;
        }
        .input_container{
            position: relative;
            overflow: hidden;
            width:100%;
            height: auto;
            background-color: green;
            // border: dotted black;
            box-sizing: border-box;

        }
        .description{
            position: relative;
            overflow: hidden;
            width:100%;
            height: 50px;
            margin:20px 0px;
            // background-color: yellow;
            text-align: center;
            border-radius: 10px;
            font-size: 20px;

        }
        .upload_button{
            position: relative;
            overflow: hidden;
            // width: 80px;
            // height:50px;
            padding: 10px 20px;
            // box-sizing: border-box;
            margin-top: 10px;
            margin-left:auto;
            margin-right: auto;
            text-align: center;
            border-radius: 35px;
            cursor: pointer;
            font-weight: 600;
            letter-spacing: 1px;
            // background-color: red;

        }
    }
    .loading_page_container{
        position:fixed;
        overflow: hidden;
        left:0px;
        top:0px;
        width: 100%;
        height: 100%;
        background-color:rgb(194, 191, 191);
        .loading_container{
          position: absolute;
          left: 50%;
          top: 50%;
          width: auto;
          height:auto;
          transform: translate(-50%,-50%);
    
        }
    }
}