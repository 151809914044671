.gallery_page_container{
  position:relative;
  overflow: hidden;
  width:100%;
  min-height: 80vh;
  // background-color: rgb(143, 143, 138);
  background-color:#1d1d1d;

  // margin-top: 70px;
  .gallery_block{
    position:relative;
    overflow: hidden;
    width: 900px;
    max-width: 95%;
    height: auto;
    margin:auto;
    margin-top: 90px;
    // background-color: red;
    .image_container{
      position:relative;
      overflow: hidden;
      width:100%;
      height: auto;
      margin-bottom: 50px;
      border-radius: 5px;
      .image_tile{
        position: relative;
        overflow: hidden;
        width: 100%;
        height:auto;
        // background-color:green;
      }
      .delete_button{
        position: absolute;
        right:0px;
        top:0px;
        width: 60px;
        height:50px;
        font-size: 25px;
        border-radius: 5px;
        // background-color: red;
        cursor: pointer;
      }
      .image_description{
        position: absolute;
        overflow:hidden;
        padding:10px;
        box-sizing: border-box;
        left:0px;
        bottom:0px;
        width:100%;
        height: 50px;
        background-image: linear-gradient(to bottom, #e9f3f47d, #cecbce);
        // background-color: white;
      }
    }
  }
  .delete_alert_page_container{
    position: fixed;
    left:0%;
    top:0%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: transparent;
    .delete_alert_container{
      position: absolute;
      overflow: hidden;
      left:50%;
      top:50%;
      transform: translate(-50%,-50%);
      width:350px;
      height:auto;
      padding:20px;
      box-sizing: border-box;
      background-color: white;
      border-radius: 10px;
      .confirm_question{
        position: relative;
        width: 100%;
        height:auto;
        padding: 10px;
        box-sizing: border-box;
        // background-color: blue;
        text-align: center;
        font-size: 25px;
        letter-spacing: 1px;
        font-weight: 500;
      }
      .confirm_answer{
        position: relative;
        width: 100%;
        height:auto;
        padding:10px;
        box-sizing: border-box;
        // margin-left: auto;
        // margin-right: auto;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        cursor: pointer;
        // background-color:green;
        .no{
          position: relative;
          overflow: hidden;
          display: inline-block;
          width: auto;
          height:auto;
          padding: 6px 20px;
          box-sizing: border-box;
          border: 1px solid transparent;
          border-radius: 5px;
          background-color: #3372bf;
          color:white;
          transition: 0.3s all;
          &:hover{
            background-color: white;
            border: 1px solid #3372bf;
            color:black;
          }
        }
        .yes{
          position: relative;
          overflow: hidden;
          display: inline-block;
          width: auto;
          height:auto;
          padding: 6px 20px;
          box-sizing: border-box;
          background-color:  #d55351;
          margin-left: 50px;
          border: 1px solid;
          border-radius: 5px;
          color:white;
          transition: 0.5s all;
          &:hover{
            background-color: white;
            border: 1px solid  #d55351;
            color:black;
          }
        }
      } 
    }
  }
  .loading_page_container{
    position:fixed;
    overflow: hidden;
    left:0px;
    top:0px;
    width: 100%;
    height: 100%;
    background-color:rgb(194, 191, 191);
    .loading_container{
      position: absolute;
      left: 50%;
      top: 50%;
      width: auto;
      height:auto;
      transform: translate(-50%,-50%);

    }
  }
}