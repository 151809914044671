.header_container{
  position:fixed;
  overflow: hidden;
  top:0px;
  left:0px;
  padding:10px;
  box-sizing: border-box;
  width:100%;
  height:80px;
  background-color:white;
  .hamburger{
    position:relative;
    overflow: hidden;
    display: inline-block;
    width: 50px;
    height: 100%;
    padding-top: 12px;
    box-sizing: border-box;
    // background-color: aqua;
    font-size: 40px;
    text-align: center;
    cursor: pointer;

  }
  .logo_container{
    position:relative;
    overflow: hidden;
    display:inline-block;
    margin-right: 10px;
    width: 100px;
    height:100%;
    // background-color: yellow;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    

  }
}
.sidebar_block{
  position:fixed;
  overflow: hidden;
  left:0px;
  top:80px;
  width:300px;
  height: 100vh;
  background-color: grey; 
  transition: all 1s;
  transform:translate(-100%,0%);
  // .logo_closebutton_container{
  //   display: grid;
  //   grid-template-columns: 30% auto 20%;
  //   background-color: red;
  //   .logo{
  //     height: 60px;
  //     padding: 10px;
  //     // background-color: yellow;
  //     background-position: center;
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //   }
  //   .close_button{
  //     height: 60px;
  //     // background-color: cyan;
  //     font-size: 40px;
  //     text-align: center;
  //   }
  // }
  .sidebar_items_container{
    position:relative;
    overflow: hidden;
    width: 100%;
    height:auto;
    // margin-top: 20px;  
    padding: 15px;
    box-sizing: border-box;

    .sidebar_item{
      position:relative;
      display: inline-block;
      overflow:hidden;
      margin-bottom: 10px;
      width:100%;
      // height:50px;
      padding: 20px;
      box-sizing: border-box;
      background-color: #e3e7e6;
      text-align: center;
      border-radius: 5px;
    }
  }
}
