.enquiries_page_container{
    position: relative;
    overflow: hidden;
    width:100%;
    height:auto;
    margin-top: 80px;   
    background-color: #9aa6a9;  
    .enquiries_container{
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        width:900px;
        max-width: 90%;
        height:auto;
        .single_enquiry_container{
            display: grid;
            grid-template-columns: 50% auto;
            margin-top: 30px;
            background-image: linear-gradient(to bottom right, #45dbb14f, #e0e3d6);
            // background-color: #49b8cb;
            color:white;
            border-radius: 20px;
            // font-size: 25px;
            font-weight: 600;
            padding:50px;
            grid-gap:30px;
            box-sizing: border-box;
            
            .name{
                height: auto;

            }

        }
        
    }
    @media screen and (max-width:700px){
        .enquiries_container{
            .single_enquiry_container{
                display:block;
                .name{
                    margin-bottom: 20px;
                }
            }
        }
    }
}