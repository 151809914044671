.view_enquiry_page_container{
    position: relative;
    overflow:hidden;
    width:100%;
    min-height: 100vh;
    height: auto;
    background-color: rgb(188, 183, 183);
    .enquiry_details_container{
        position:relative;
        overflow: hidden;
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
        width:70%;
        height:auto;
        border-radius: 10px;
        background-image: linear-gradient(to bottom right, rgba(63, 202, 137, 0.52), #e0e3d6);
        .name{
           position: relative;
           overflow:hidden;
           padding:20px;
           box-sizing: border-box;
           width:auto;
           height:auto;
        //    background-color: green;
        }
    }
}